import React, { useState } from 'react';
import axios from 'axios';
import image from '../../Assets/BookAppointmentbg.png';

const BookAppointment = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_KEY}/email-service/sendEmail`, {
        managerEmail: "kairasaloon@gmail.com",
        ...formData,
      });

      if (response.data.success) {
        setSuccess(true);
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: ''
        });
      } else {
        setError('Failed to send the message. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to send the message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex items-center justify-center mt-7"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="p-6 md:p-24 rounded-lg z-10 md:w-[70%]">
        <h1 className="text-4xl font-bold text-black mb-4 md:mb-8 text-center tracking-wide">
          Book An Appointment!
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full md:w-1/2 p-3 border border-gray-400 rounded-md focus:outline-none"
              required
            />
            <input
              type="text"
              placeholder="Number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full md:w-1/2 p-3 border border-gray-400 rounded-md focus:outline-none"
              required
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border border-gray-400 rounded-md focus:outline-none"
            required
          />
          <textarea
            placeholder="Tell Us More"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-3 border border-gray-400 rounded-md focus:outline-none h-32"
            required
          ></textarea>
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 justify-center">
            <div className="text-center flex flex-col md:flex-row items-center gap-4">
              <button
                type="submit"
                className="bg-[#000000] text-white py-3 w-36 rounded hover:bg-[#5d5d5d]"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Book Now'}
              </button>
              <button
                type="button"
                className="border border-gray-400 py-3 w-24 rounded-md hover:bg-gray-400"
              >
                Skip
              </button>
            </div>
          </div>
        </form>

        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
        {success && <p className="mt-4 text-green-500 text-center">Your appointment has been booked successfully!</p>}
      </div>
    </div>
  );
};

export default BookAppointment;
