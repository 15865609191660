import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SpaPackagesicons from '../../Assets/SpaPackagesicons.png';

const OurServices = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAllServices = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/services/getAllServiceByBranchId/674587629aa6780fb268869e`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setServices(response.data);
                setLoading(false); // Set loading to false once the data is fetched
            })
            .catch((error) => {
                console.log(error);
                setLoading(false); // In case of error, set loading to false
            });
    };

    useEffect(() => {
        getAllServices();
    }, []);

    const handleBookNow = (service) => {
        const phoneNumber = '919777172793'; // Replace with your phone number including country code
        const message = `Hello, I would like to book the service: ${service?.name}. Price: ₹ ${service?.price} for ${service?.duration} minutes.`;

        // URL encode the message to make sure it's formatted correctly
        const encodedMessage = encodeURIComponent(message);

        // Open WhatsApp with pre-filled message
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        // Open the WhatsApp chat
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className="md:m-16 m-6 text-center flex flex-col items-center">
            <h1 className="text-4xl font-semibold tracking-wide text-center text-white mb-4">Our Services</h1>
            <p className=" text-[#EEEEEE] w-full lg:w-[60%] capitalize opacity-80 tracking-wide">
                We Seek To Build A Lasting Relationship With You Based On Excellence, Integrity And Forward-Thinking.
            </p>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-7 w-full'>
                {loading && (
                    Array.from({ length: 3 }).map((_, index) => (
                        <ServiceCardSkeleton key={index} />
                    ))
                )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-7">
                {/* Pass loading state to ServiceCard */}
                {!loading && services?.map((service, index) => (
                    <ServiceCard
                        key={index}
                        image={service?.image}
                        icon={service?.icon}
                        title={service?.name}
                        description={service?.description}
                        price={service?.price}
                        duration={service?.duration}
                        animation={service?.animation}
                        onClick={() => handleBookNow(service)}
                    />
                ))}
            </div>
        </div>
    );
};

export default OurServices;

const ServiceCard = ({ image, title, description, price, animation, duration, onClick }) => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:scale-105 transition-all duration-300 ease-in-out flex flex-col h-full" data-aos={animation}>
        <div className='relative'>
            <img src={image} alt={title} className="w-full object-cover" />
            <img src={SpaPackagesicons} alt='icons' className='absolute xl:-bottom-10 xl:left-40 lg:-bottom-12 lg:left-24 lg:left-30 md:-bottom-12 md:left-28 -bottom-10 left-24' />
        </div>
        <div className="p-6 flex flex-col flex-grow mt-2">
            <h2 className="text-xl font-semibold text-center mb-2">{title}</h2>
            <p className="text-gray-600 text-center mb-4">{description}</p>
            <div className="flex-grow" /> {/* This ensures that the content area grows and pushes the button down */}
            <p className="text-center text-black font-semibold mb-4">Starts From: ₹{price}/{duration}</p>
            <div className="text-center">
                <button
                    onClick={onClick}
                    className="bg-[#000000] text-white py-3 w-36 rounded">Book Now</button>
            </div>
        </div>
    </div>
);

const ServiceCardSkeleton = () => (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden animate-pulse flex flex-col h-full">
        <div className="relative p-4">
            <div className="w-full h-60 bg-gray-300 rounded-lg" />
            <div className="absolute bottom-4 left-4 w-16 h-16 bg-gray-300 rounded-full" />
        </div>
        <div className="p-6 flex flex-col flex-grow mt-2">
            <div className="h-6 bg-gray-300 rounded mb-2"></div>
            <div className="h-4 bg-gray-300 rounded mb-4 w-3/4 mx-auto"></div>
            <div className="flex-grow" /> {/* This ensures that the content area grows and pushes the button down */}
            <div className="h-8 bg-gray-300 rounded mb-4 w-1/2 mx-auto"></div>
            <div className="text-center">
                <div className="w-36 h-12 bg-gray-300 rounded mx-auto"></div>
            </div>
        </div>
    </div>
);

