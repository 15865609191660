import React, { useEffect, useState } from "react";
import star from "../../Assets/star.png";
import axios from "axios";

// PricingCard Component (Real Pricing Card)
const PricingCard = ({ plan, animation,handleBookNow }) => (
  <div
    className="bg-white shadow-lg rounded-lg overflow-hidden mb-6 mx-2"
    data-aos={animation}
  >
    <div className="relative">
      <img src={plan?.image} alt={plan?.alt} className="object-cover w-full" />
      <div className="absolute top-0 left-0 bg-gray-800 text-white text-xs font-semibold px-2 py-1 rounded-br-lg">
        {plan?.type}
      </div>
      <div className="absolute bottom-0 left-0 bg-gray-800 text-white text-2xl px-4 py-2">
        ₹{plan?.price}
        <span className="text-sm"> /per day</span>
      </div>
    </div>
    <div className="p-6">
      <ul className="space-y-2 text-black">
        {plan?.keyPoints.map((feature, index) => (
          <li key={index} className="flex items-center gap-4">
            <img src={star} alt="" />
            {feature}
          </li>
        ))}
      </ul>
      <button
      onClick={() => handleBookNow(plan)}
      className="mt-6 w-full bg-black text-white py-2 rounded-lg font-semibold hover:bg-[#5d5d5d]">
        Choose Plan
      </button>
    </div>
  </div>
);

// Skeleton Loader Component for PricingCard
const PricingCardSkeleton = () => (
  <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6 mx-2 animate-pulse">
    <div className="relative">
      <div className="w-full h-56 bg-gray-300" />
      <div className="absolute top-0 left-0 bg-gray-300 text-xs font-semibold px-2 py-1 rounded-br-lg w-24 h-6" />
      <div className="absolute bottom-0 left-0 bg-gray-300 text-2xl px-4 py-2 w-32 h-8" />
    </div>
    <div className="p-6">
      <div className="space-y-2">
        <div className="w-32 h-4 bg-gray-300 rounded" />
        <div className="w-40 h-4 bg-gray-300 rounded" />
        <div className="w-28 h-4 bg-gray-300 rounded" />
      </div>
      <div className="mt-6 w-full h-10 bg-gray-300 rounded-lg" />
    </div>
  </div>
);

const OurPricing = () => {
  const animations = ["fade-up", "fade-right", "fade-left"];
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const getALlPlans = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/plans/getAllPlansByBranchId/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setPlans(response.data);
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Handle error by stopping the loading state
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getALlPlans();
  }, []);

  const handleBookNow = (plan) => {
    const phoneNumber = '919777172793'; // Replace with your phone number including country code
    const message = `Hello, I would like to know more about the plan: ${plan?.type}. Price: ₹ ${plan?.price}.`;

    // URL encode the message to make sure it's formatted correctly
    const encodedMessage = encodeURIComponent(message);

    // Open WhatsApp with pre-filled message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open the WhatsApp chat
    window.open(whatsappUrl, '_blank');
};

  return (
    <div className="text-gray-800">
      <div className="text-center py-12">
        <h1 className="text-4xl font-semibold text-white tracking-wide">
          Our Pricing
        </h1>
        <p className="mt-4 text-lg text-white tracking-wide leading-loose">
          We Seek To Build A Lasting Relationship With You Based On Excellence,
          Integrity And Forward-Thinking.
        </p>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 mx-6 md:mx-16">
        {/* Show skeleton loader while loading */}
        {loading ? (
          Array(3)
            .fill()
            .map((_, index) => <PricingCardSkeleton key={index} />)
        ) : (
          plans?.map((plan, index) => (
            <PricingCard
              key={index}
              plan={plan}
              animation={animations[index % animations.length]}
              handleBookNow={handleBookNow}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default OurPricing;
