import React from "react";
import "./HeroSection.css";
import { useState, useEffect } from "react";
import axios from "axios";

const HeroSection = () => {
  const [heroSectionData, setHeroSectionData] = useState();
  const [loading, setLoading] = useState(true); // Loading state to control the skeleton loader

  const showHeroSection = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response : ", response.data);
        setHeroSectionData(response.data.data);
        setLoading(false); // Once the data is fetched, set loading to false
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // In case of error, stop loading
      }).finally(() => {
        setLoading(false); // Set loading to false regardless of success or error
      });
  };

  const handleBookNow = () => {
    const phoneNumber = '919777172793';
    const message = `Hello, I would like to book an appointment.`;

    // URL encode the message to make sure it's formatted correctly
    const encodedMessage = encodeURIComponent(message);

    // Open WhatsApp with pre-filled message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open the WhatsApp chat
    window.open(whatsappUrl, '_blank');
  };
  useEffect(() => {
    showHeroSection();
  }, []);

  return (
    <React.Fragment>
      <div
        className={`hidden md:block md:py-20 h-auto text-center ${loading ? "bg-gray-300 animate-pulse" : ""
          }`} // Add skeleton loader background
        style={{
          backgroundImage: loading
            ? "none"
            : `url(${heroSectionData?.[0]?.bgImg})`, // Display background once data is loaded
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        {/* Content Skeleton */}
        {loading ? (
          <div className="text-start text-[#F1F0F0] p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
            <div className="w-3/4 h-8 bg-gray-400 rounded mb-4"></div> {/* Skeleton for Heading */}
            <div className="w-5/6 h-6 bg-gray-400 rounded mb-6"></div> {/* Skeleton for Subheading */}
            <div className="w-36 h-12 bg-gray-400 rounded"></div> {/* Skeleton for Button */}
          </div>
        ) : (
          <div className="text-start text-[#F1F0F0] p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
            <h1 className="text-3xl md:text-6xl font-medium mb-4 tracking-widest font-serif">
              {heroSectionData?.[0]?.heading}
            </h1>
            <p className="text-base md:text-lg mb-6 tracking-wide leading-relaxed">
              {heroSectionData?.[0]?.subheading}
            </p>

            <button
            onClick={handleBookNow}
            className="bg-[#F0F0F0] text-black py-3 w-36 rounded hover:bg-[#656565]">
              Book Now
            </button>
          </div>
        )}
      </div>

      {/* For Mobile Screen */}
      <div
        className={`md:hidden block py-10 h-auto text-center ${loading ? "bg-gray-300 animate-pulse" : ""
          }`} // Add skeleton loader background
        style={{
          backgroundImage: loading
            ? "none"
            : `url(${heroSectionData?.[0]?.bgImg})`, // Display background once data is loaded
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
        }}
      >
        {/* Content Skeleton */}
        {loading ? (
          <div className="text-start text-white p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
            <div className="w-3/4 h-8 bg-gray-400 rounded mb-4"></div> {/* Skeleton for Heading */}
            <div className="w-5/6 h-6 bg-gray-400 rounded mb-6"></div> {/* Skeleton for Subheading */}
            <div className="w-36 h-12 bg-gray-400 rounded"></div> {/* Skeleton for Button */}
          </div>
        ) : (
          <div className="text-start text-white p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
            <h1 className="text-3xl md:text-6xl font-medium mb-4 tracking-wider">
              {heroSectionData?.[0]?.heading}
            </h1>
            <p className="text-base md:text-lg mb-6 tracking-wide mt-6">
              {heroSectionData?.[0]?.subheading}
            </p>

            <button className="bg-[#F0F0F0] text-black py-3 w-36 rounded  hover:bg-[#656565]">
              Book Now
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default HeroSection;
