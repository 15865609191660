import { React, useState, useEffect } from 'react';

import imgs1 from '../../Assets/img1.jpg'
import imgs2 from '../../Assets/img2.jpg'
import imgs3 from '../../Assets/img3.jpg'
import axios from "axios";
const ClientsSay = () => {

    const [reviews, setReviews] = useState([]);
    const fetchReviews = async () => {
        let config = {
          method: "get",
          maxBodyLenght: Infinity,
          url: `${process.env.REACT_APP_API_KEY}/reviews/fetchReviews?PLACE_ID=ChIJwYrMUZflIDoRi2Xm2lHbSMc&API_KEY=XYZ`,
        };
    
        axios
          .request(config)
          .then((response) => {
            console.log("Response---->", response.data);
            setReviews(response.data);
          })
          .catch((error) => {
            console.log("Error in fetching reviews", error);
          });
      };
    
      useEffect(() => {
        fetchReviews();
      }, []);

    return (
        <div
            className='text-center py-14'
            
        >
            <h1 className="text-4xl font-bold text-white mb-4 tracking-wide">What Our Clients Say</h1>
            <p className="text-lg text-white mb-8 tracking-wide leading-loose">We Seek To Build A Lasting Relationship With You Based On Excellence, Integrity And Forward-Thinking.</p>
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-10 md:mx-16 mx-6">
                <div
                    className="bg-gradient-to-b from-[#E6E6E6]  to-gray-600 rounded-lg shadow-lg md:p-12 p-6"
                >
                    <div className="w-24 h-24 rounded-full mx-auto">
                        <img src={imgs3} alt='img' className='w-24 h-24 rounded-full object-cover' />
                    </div>
                    <p className="mt-4 text-white tracking-wide leading-relaxed">Kaira Saloon exceeded my expectations! The staff is friendly, and my spa treatment was so relaxing. I left feeling rejuvenated and pampered. Will definitely be returning!"</p>
                    <h3 className="mt-4 text-xl font-bold text-white mb-4 tracking-wide">Chinmay Sharma</h3>
                    <p className="text-white tracking-wide">Doctor</p>
                </div>
                <div
                    className="bg-gradient-to-b from-[#E6E6E6]  to-gray-600 rounded-lg shadow-lg md:p-12 p-6"
    
                >
                    <div className="w-24 h-24 rounded-full mx-auto">
                        <img src={imgs2} alt='img' className='w-24 h-24 rounded-full object-cover' />
                    </div>
                    <p className="mt-4 text-white tracking-wide leading-relaxed">I had a manicure and pedicure at Kaira Saloon, and the attention to detail was amazing. The nail technicians are skilled, and the results were flawless. Highly recommend!"</p>
                    <h3 className="my-4 text-xl font-bold text-white
                    ">Rabindra Kumar Dehury</h3>
                    <p className="text-white tracking-wide">Doctor</p>
                </div>
                <div
                    className="bg-gradient-to-b from-[#E6E6E6]  to-gray-600 rounded-lg shadow-lg md:p-12 p-6"
                   
                >
                    <div className="w-24 h-24 rounded-full mx-auto">
                        <img src={imgs1} alt='img' className='w-24 h-24 rounded-full object-cover' />
                    </div>
                    <p className="mt-4 text-white tracking-wide leading-relaxed">"Kaira Saloon’s facials have done wonders for my skin. The products are high-quality, and my skin has never felt better. It’s now my go-to spot for skincare!"</p>
                    <h3 className="my-4 text-xl font-bold text-white ">Taniya Sharma</h3>
                    <p className="text-white tracking-wide">Doctor</p>
                </div>
            </div>
        </div>
    );
}

export default ClientsSay;