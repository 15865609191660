import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

import Team1 from "../../Assets/Team1.png";

const OurTeam = () => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, [team]);

  const showTeam = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamByBranchId/674587629aa6780fb268869e`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setTeam(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    showTeam();
  }, []);

  // Skeleton Loader Component
  const TeamMemberSkeleton = () => (
    <div className="bg-[#FFF1F6] shadow-lg rounded-lg overflow-hidden animate-pulse">
      <div className="h-56 bg-gray-300 w-full" />
      <div className="p-4 text-center">
        <div className="h-6 bg-gray-300 w-2/3 mx-auto mb-4" />
        <div className="h-4 bg-gray-300 w-1/3 mx-auto" /> 
      </div>
    </div>
  );

  return (
    <div className="py-10">
      <div className="text-center py-12 flex flex-col items-center">
        <h1 className="text-4xl font-bold text-white tracking-wide">Meet Our Team</h1>
        <p className="mt-4 text-white md:w-[70%] tracking-wide leading-relaxed">
          Passionate Experts Ready To Serve You: Uniting Skill, Dedication, And Innovation For Your Ultimate Satisfaction.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-6 md:mx-16">
        {loading ? (
          Array(4)
            .fill()
            .map((_, index) => <TeamMemberSkeleton key={index} />) 
        ) : (
          team.map((member, index) => (
            <div
              key={index}
              className="bg-[#FFF1F6] shadow-lg rounded-lg overflow-hidden"
              data-aos="fade-up"
            >
              <img
                alt={`Portrait of ${member.name}`}
                className="w-full object-cover"
                src={member?.profilePic || Team1}
              />
              <div className="p-4 text-center">
                <h2 className="text-xl font-bold mb-4 tracking-wide">
                  {member?.name || "Default Name"}
                </h2>
                <p className="text-gray-600 tracking-wide">
                  {member?.role || "Default Role"}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OurTeam;
