import React, { useState, useEffect } from 'react';
import './OffersZone.css';
import ofr1 from '../../Assets/offer1.png';

const offers = [
    { id: 1, name: 'Haircut Special', icon: ofr1 },
    { id: 2, name: 'Nail Art', icon: ofr1 },
    { id: 3, name: 'Spa Treatment', icon: ofr1 },
    { id: 4, name: 'Hair Coloring', icon: ofr1 },
    { id: 5, name: 'Facial Care', icon: ofr1 },
    { id: 6, name: 'Massage Therapy', icon: ofr1 },
];

const OffersZone = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Function to get the current 3 offers based on the index
    const getVisibleOffers = () => {
        return offers.slice(currentIndex, currentIndex + 3);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            // Update index every 2 seconds, loop back to the start when all offers have been shown
            setCurrentIndex((prevIndex) =>
                prevIndex + 3 >= offers.length ? 0 : prevIndex + 3
            );
        }, 4000); // Change every 2 seconds

        return () => clearInterval(interval); // Clean up on component unmount
    }, []);

    return (
        <div className=" py-10 relative px-6">
            <div className="text-center pb-6">
                <h1 className="text-4xl font-semibold tracking-wide text-white ">Offers Zone</h1>
            </div>

            {/* Scrolling Offers Section */}
            <div className="offers-wrapper">
                {getVisibleOffers().map((offer) => (
                    <div key={offer.id} className="offer-item">
                        <img
                            src={offer.icon}
                            className='h-52 w-auto object-contain'
                            alt="ofr-img"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OffersZone;
