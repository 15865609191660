import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';

const OurOffers = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state

    const getAllOffers = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/offer/getAllOffersByBranchId/674587629aa6780fb268869e`,
            headers: {},
        };

        axios
            .request(config)
            .then((response) => {
                setOffers(response?.data);
                setLoading(false); // Data has been fetched, set loading to false
            })
            .catch((error) => {
                console.log(error);
                setLoading(false); // In case of error, set loading to false
            });
    };

    useEffect(() => {
        getAllOffers();
    }, []);

    return (
        <div className="md:mx-16 mx-6 mt-10">
            <h1 className="text-4xl font-bold text-center text-white mb-2 tracking-wide">Our Offers</h1>
            <p className="text-center text-white mb-8 tracking-wide leading-relaxed">
                We Seek To Build A Lasting Relationship With You Based On Excellence, Integrity And Forward-Thinking. FG
            </p>

            {/* Skeleton loader for offers */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {loading
                    ? [...Array(3)].map((_, index) => (
                        <div
                            key={index}
                            className="bg-[#DEDEDE] p-4 rounded-lg shadow-md animate-pulse"
                        >
                            <div className="w-full h-48 bg-gray-300 mb-4 rounded-lg"></div> {/* Skeleton image */}
                            <div className="w-full h-6 bg-gray-300 rounded mb-2"></div> {/* Skeleton title */}
                            <div className="w-full h-4 bg-gray-300 rounded"></div>
                            <div className="w-full h-4 bg-gray-300 rounded"></div>
                            <div className="w-full h-4 bg-gray-300 rounded"></div>
                            <div className="w-full h-4 bg-gray-300 rounded"></div>
                            <div className="w-full h-4 bg-gray-300 rounded"></div>
                        </div>
                    ))
                    : offers?.map((offer) => (
                        <div
                            key={offer?.id}
                            className="bg-[#DEDEDE] p-4 rounded-lg shadow-md"
                            data-aos={offer?.aosEffect}
                        >
                            <img
                                src={offer?.image}
                                alt={offer?.imageAlt}
                                className="w-full rounded-lg mb-4 object-contain"
                            />
                            <h2 className="text-xl font-bold mb-2">{offer?.name}</h2>
                            <p className="text-gray-600">{offer?.description}</p>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default OurOffers;
